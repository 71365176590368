.printTable {
  /* border: 1px solid #ccc; */
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  color: #000;
  display: none;
}

@media print {
  .printTable {
    display: table;
  }
}

.printTable caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

.printTable tr {
  /* border: 1px solid #ddd; */
  padding: .35em;
}

.printTable th,
.printTable td {
  padding: .625em;
  text-align: center
}

.printTable th {
  /* letter-spacing: .1em; */
  text-transform: capitalize;
  background-color: #a2a3a3;
  text-align: center;
  font-size: 18px;
}

.printTable td {
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  /* border-bottom: 1px dashed #000; */
}

.printTable tr {
  border-bottom: 1px dashed #000;
}

@media screen and (max-width: 600px) {
  .printTable {
    border: 0;
  }

  .printTable caption {
    font-size: 1.3em;
  }
  
  .printTable thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .printTable tr {
    display: block;
    margin-bottom: .625em;
  }
  
  .printTable td {
    display: block;
  }
  
  .printTable td::before {
    /*
    * aria-label has no advantage, it won't be read inside a .printTable
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  /* .printTable td:last-child {
    border-bottom: 0;
  } */
}