@media print {
  .print {
    position: relative;
    padding-bottom: 250px;
  }
}

.printBody {
  direction: rtl;
  color: #000;
  display: none;
}
/* --main-color: #F27925; */
/* --secondary-color: #243F93; */
@media print {
  .printBody {
    display: block;
    margin-top: 1rem;
  }
}

.printBody header {
  /* direction: ltr; */
  display: flex;
  /* flex-direction: column; */
  font-size: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #000;
  position: relative;
  gap: 10px;
}

header::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 10px;
  translate: 0% 100%;
  background-color: var(--main-color);
}

.qr {
  width: 50px;
  height: 50px;
}

.qr img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.headerContent {
  display: flex;
  flex-direction: column;
  direction: ltr;
  text-align: right;
  font-size: 12px;
}

main {
  margin-top: 1rem;
  display: flex;
  direction: rtl;
  align-items: center;
  gap: 20px;
  margin-top: 60px;
}

.comp h1 {
  font-size: 30px;
  color: #000;
}

h1 {
  margin-top: 10px;
}

.logo {
  width: 160px;
  height: 160px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50%;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.date {
  font-size: 16px;
  font-weight: bold;
}

h2 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: normal;
}

/* footer {
  display: none;
  margin-top: 10mm;
  justify-content: space-between;
} */

/* @media print {
  footer {
    display: flex;
  }
} */

/* footer p:first-child {
  font-size: 18px;
  font-weight: bold;
} */

@media print {
  .tableWrapper {
    display: block;
    border: 1px solid #eee;
  }
}

.extraField {
  /* display: flex; */
  /* gap: 50px; */
  text-align: center;
  margin-top: 3rem;
  /* margin-bottom: 3rem; */
  color: #fff;
}

.extraField > div {
  color: #000;
  margin-top: 6px;
}

.extraField > div > p {
  color: #000;
  margin-top: 0;
  margin-bottom: 0;
}

/* new stuff  */

.company {
  direction: rtl;
}

.company span {
  display: block;
  font-size: 12px;
  font-style: italic;
}

.company h3 {
  color: #000;
}

.company p {
  font-weight: 600;
}

.comp h1 {
  font-size: 35px;
  color: #000;
}
/* end stuff  */

.belowTable {
  direction: rtl;
  color: #000;
  margin-top: 20px;
  display: none;
  font-size: 20px;
}

@media print {
  .belowTable {
    display: block;
  }
}

.belowTable div {
  display: flex;
}

.belowTable p {
  font-size: 18px;
  font-weight: bold;
}

.belowTable p {
  width: 20%;
  text-align: center;
  margin: 0;
}

.belowTable div:not(:last-child) p:not(:first-child)::after {
  content: "...................................";
  font-weight: normal;
  display: block
}

.accepted {
  direction: rtl;
  margin-top: 70px;
  padding-right: 54px;
  display: none;
}

@media print {
  .accepted {
    display: block;
  }
}

.accepted span {
  border-top: 1px solid #000;
  position: relative;
  font-weight: bold;
  color: #000;
  padding-top: 15px;
  display: inline-block;
  font-size: 22px;
}

.accepted span:before {
  content: "";
  position: absolute;
  background-color: #a09f9e80;
  top: 0;
  right: 0;
  height: 15px;
  width: 100%;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #444;
  width: 44%;
  display: none;
}

@media print {
  footer {
    display: block;
  }
}

footer div {
  display: block;
  position: relative;
  padding-top: 20px;
  border-top: 1px solid #000;
}

footer div:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15px;
  background-color: #a09f9e80;
}

.headerSection {
  position: relative;
  padding-top: 30px;
}

.textUpperTable p {
  font-size: 18px;
}