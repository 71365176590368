.dialogbox {
  position: relative;
  /* max-width: 300px; */
  height: auto;
  margin: 20px 10px;
  padding: 5px;
  padding-left: 12px;
  background-color: #ecf0f1;
  border-radius: 8px;
  border: 5px solid #c9d0d5;
}

.dialogbox .message {
  min-height: 30px;
  border-radius: 3px;
  font-family: Arial;
  font-size: 14px;
  line-height: 1.5;
  color: #797979;
}

.message span {
  font-size: 16px;
  padding-left: 4px;
}

.dialogbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, 0);
  border-style: solid;
  border-width: 10px;
  border-color: transparent #ccc transparent transparent;
}

:global(.rtl) .dialogbox::before {
  left: unset;
  right: 0;
  transform: translate(100%, 0);
  border-color: transparent transparent transparent #ccc;
}

.date {
  text-align: right;
  font-size: 13px;
  margin-top: 10px;
  margin-right: 10px;
}

.img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.message div:first-child {
  font-weight: bold;
  
}