:root {
  --main-green-color: #2dce89;
  --main-blue-color: #172b4d;
  --toggle-ball-width: 18px;
  --toggle-width: 65px;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

html::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.loading-parent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

/* .loading-child {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-bootstrap-table {
  overflow-x: scroll;
}

.table-content {
  text-align: initial;
}

.center-drop {
  align-self: center;
}

.page-item.active .page-link {
  background-color: #2dce89;
  border-color: #2dce89;
}

body {
  text-align: initial;
}

.rtl .modal-footer .btn + .btn {
  margin-left: 0;
}

.padding-half {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* start global classes  */
.pointer {
  transition: filter 0.2s ease-in-out;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer:hover {
  cursor: pointer;
  filter: brightness(90%);
}

un .nothing:hover {
  color: #fff;
  cursor: unset;
}

.me-1 {
  margin-inline-end: 1rem;
}

.me-2 {
  margin-inline-end: 2rem;
}

.ms-1 {
  margin-inline-start: 1rem;
}

.ms-2 {
  margin-inline-start: 2rem;
}

.pd-sides-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.selected {
  background-color: #e7e5e5;
}

.golden {
  background-color: #f1c40f3b;
}

.disabled {
  pointer-events: none;
}

/* end global classes  */

/* start global color  */

.text-gold {
  color: #bfb21e;
}

.hover-danger,
.hover-info,
.hover-warning,
.hover-success,
.hover-primary {
  transition: color 0.2s ease-in-out;
}

.hover-danger:hover {
  color: var(--danger);
}

.hover-info:hover {
  color: var(--info);
}

.hover-success:hover {
  color: var(--success);
}

.hover-warning:hover {
  color: var(--warning);
}

.hover-primary:hover {
  color: var(--primary);
}

.padding-around {
  padding: 1rem;
}

/* end global color  */

/* start the main navbar */
.main-content .navbar-top {
  padding-top: 40px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
/* end the main navbar */

.table td,
.table th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  vertical-align: center;
}

label {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.table thead th {
  font-size: 0.75rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #fff;
  color: #2dce89;
}

.nav-pills .nav-link {
  color: #ffffff;
  background-color: #2dce89;
}

.nav-pills .nav-link:hover {
  color: inherit;
}

.react-bootstrap-table-pagination-total {
  color: white;
}

.form-control:focus {
  border-color: #888686;
}

.page-item {
  padding: 0.15rem;
}

.pagination {
  padding-left: 1.5rem;
}

.toggler {
  padding-left: 1.5rem;
  padding-top: 1rem;
}

.saving {
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: 3rem;
}

.react-bootstrap-table .table {
  overflow: auto;
}

.toggleRow {
  padding-top: 2rem;
}

.selectpicker {
  font-size: 0.875rem;
  transition: all 0.15s ease-in-out;
  height: calc(1.5em + 1.25rem + 5px);
}

.selectpicker {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.fa-check {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.navbar-vertical
  .navbar-nav
  .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
  color: #2dce89;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.approval {
  margin-bottom: 3rem;
}

.react-bootstrap-table {
  padding-top: 2rem;
}

/* this need to be fixed*/
/* i {
 padding-left: 1rem;
 padding-right: 1rem;
} */
.custom-row i {
  padding-left: 1rem;
  padding-right: 1rem;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn.active {
  background-color: #2dce89;
  color: #fff;
}

[data-toggle="buttons"]:not(.btn-group-colors) > .btn {
  font-size: x-small;
}

/* .custom-toggle-slider { */
/* width: 65px; */
/* } */
.custom-toggle {
  width: var(--toggle-width);
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  transform: translateX(
    calc(var(--toggle-width) - (var(--toggle-ball-width) * 1.35))
  );
}

.custom-toggle-slider:after {
  padding: 0 4px;
}

.custom-toggle input:checked + .custom-toggle-slider:after {
  padding: 0 4px;
}
.uploadbtn {
  margin-bottom: 2rem;
}

.custom-context-menu {
  z-index: 10;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.menuoption {
  cursor: pointer;
  padding: 10px 30px;
}

.menuoption:hover {
  background: rgba(158, 157, 157, 0.726);
}
.menuspan {
  font-size: 10px;
  color: #9e9d9d;
}

/* start file type popup*/

@media (min-width: 576px) {
  .custom-modal-popup {
    max-width: 750px;
  }
}

/* end file type popup*/

/* start normTable1  */

.custom-row {
  transition: background-color 0.23s ease-in-out;
}

.custom-row:hover {
  cursor: pointer;
  background-color: rgb(0 0 0 / 0.1);
}

.modal-header .close {
  padding: 0;
  margin: 0;
}

/* end normTable1  */

.upload-content-custom {
  display: block;
}

.upload-content-custom .custom-delete-button {
  position: static;
}

.modal-header .custom-close {
  padding: 0;
  margin: 0;
}

/* start upload tracking  */

.custom-upload-tracking {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.custom-upload-tracking .custom-close-btn {
  background: unset;
  border: unset;
}

.custom-upload-tracking .custom-arrow-btn {
  border: unset;
  background: unset;
  display: flex;
  justify-content: flex-end;
  width: 21px;
  margin-right: 4px;
  margin-left: 4px;
}

.custom-upload-tracking button {
  cursor: pointer;
}

/* end upload tracking  */

.reset-btn {
  height: 43px;
  width: 43px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border: 0;
  color: var(--main-green-color);
  border-radius: 50%;
}

.back-btn {
  height: 40px;
  width: 40px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 23px;
  color: var(--success);
  cursor: pointer;
  border: unset;
  transition: all 0.2s ease-in-out;
}

.back-btn:hover {
  filter: brightness(0.96);
}

/* start file navigator  */

.back-btn-wrapper {
  text-align: left;
}

.file-navigator {
  max-height: calc(100vh - 100px);
  width: 100%;
}

.file-navigator .table-wrapper {
  overflow-y: scroll;
}

.file-navigator .table-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.file-navigator .table-wrapper::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.file-navigator .table-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa;
}
/* end file navigator  */

/* start error animation  */
.error {
  border-color: var(--danger) !important;
  -webkit-animation: error-animation 0.25s ease-in-out 2;
  animation: error-animation 0.25s ease-in-out 2;
}

div.error {
  border: 1px solid;
  border-color: var(--danger) !important;
  -webkit-animation: error-animation 0.25s ease-in-out 2;
  animation: error-animation 0.25s ease-in-out 2;
}

.input-group-prepend {
  margin-right: 0;
}

@-webkit-keyframes error-animation {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  33% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  66% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes error-animation {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  33% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  66% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
/* end error animation  */
.marg {
  margin-left: 3em;
}
.padd {
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 4em;
}

/* edit modal size */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 750px;
  }
}

.pagination-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #565252;
  background: #fff;
  border: 1px solid #b7b2b2;
  margin-inline-end: 10px;
}

.pagination-number {
  margin-inline-end: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: var(--success);
  color: #fff;
  display: grid;
  place-items: center;
  box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
}

.gallery img {
  object-fit: contain;
}

.signup .form-group:focus-within .input-group {
  box-shadow: 0 3px 9px rgba(50, 50, 9, 0), 3px 4px 8px rgba(94, 114, 228, 0.1);
}

.signup .form-group:focus-within .input-group-alternative {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
}

/* start popup table */
.popup-customer-list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 100px;
  overflow-y: auto;
}

.popup-customer-list li {
  display: inline-block;
  background: #5e72e4;
  padding: 8px;
  color: #fff;
  margin-inline-end: 3px;
  border-radius: 8px;
  margin-bottom: 3px;
}

.popup-customer-list i {
  cursor: pointer;
  margin-inline-end: 10px;
  margin-inline-start: 6px;
}
/* end popup table */

/* start bulk  */
.bulk-pkg span {
  width: 70%;
  display: block;
}

.popup-bigger ul {
  padding-left: 16px;
  padding-right: 16px;
}

/* end bulk  */

/* start bulk details  */
.bulk-clients {
  display: flex;
  background-color: #e9ecef;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

/* end bulk details  */

/* start handle attachment  */

.attachment {
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  overflow-y: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
}

.attachment::-webkit-scrollbar {
  height: 7px;
}

/* Track */
.attachment::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.attachment::-webkit-scrollbar-thumb {
  background: #888;
  width: 100px;
  width: 100px;
  border-radius: 100px;
}

/* Handle on hover */
.attachment::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.attachment a {
  margin-right: 12px;
  font-size: 14px;
}

.attachment i {
  object-fit: contain;
  align-self: center;
  font-size: 45px;
}
/* end handle attachment  */

.input-label {
  border: 1px solid #eee;
  display: block;
  padding-top: 12px;
}

/* Start Print style */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: A4;
  /* margin: 20mm; */
  margin: 5mm;
}

@media print {
  body {
    background-color: #fff;
  }

  .print {
    display: block;
  }

  .notPrint {
    display: none;
  }
}

@media print {
  .overflow-print {
    overflow: hidden;
  }
}
/* End Print style */

.rtl-col {
  direction: rtl;
  margin-left: auto;
  text-align: right;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2dce89;
}

.nav-pills .nav-link {
  color: #2dce89;
  background-color: #fff;
}

.ticket-holder {
  flex-wrap: nowrap;
  overflow-y: auto;
  padding-bottom: 10px;
  justify-content: flex-start;
}

.ticket-holder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.ticket-holder::-webkit-scrollbar {
  height: 6px;
  background-color: #f5f5f5;
}

.ticket-holder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa;
}

.input-view {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #e9ecef;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.input-view a {
  color: unset;
}

.input-view i {
  font-size: 17px;
}

.initiated {
  /* box-shadow: inset 0 0 7px 0 var(--danger); */
  background-color: #f9e4b3;
}

.contacted {
  background-color: #c3aa50;
  color: #fff;
}

.searched {
  display: inline-block;
  background: #5e72e4;
  padding: 8px;
  color: #fff;
  -webkit-margin-end: 3px;
  margin-inline-end: 3px;
  border-radius: 8px;
  margin-bottom: 3px;
}
